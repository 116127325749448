import React, { useEffect, useState } from "react";
import { tow_truck_no_bg } from "../../../images";
import { useSelector } from "react-redux";
import { generateQuotingPriceRequest } from "../../../services/agentlessFormService";
import { toast } from "react-toastify";
import FormRadioGroup from "../../../components/molecules/FormRadioGroup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getLeadsById } from "../../../services/leads";
import errorMessage from "../../../util/errorMessage";
import { DotsLoader, FormInput, FormSelectInput } from "../../../components";
import { useNavigate } from "react-router-dom";
import LeadRejectForm from "../../Client/View/ServiceForm/LeadRejectForm";

function QuoteModal({
  formik,
  onCancelModal,
  modalClass,
  childrenClass,
  id,
  jobDetail,
}) {
  const { quote } = useSelector((state) => state.leads);
  const [isRejectFormModal, setRejectFormModal] = React.useState(false);
  const [isSchedule, setIsSchedule] = React.useState("false");
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const openRejectFormHandler = () => {
    const c = window.confirm(`Are you sure you want to decline this job?`);
    if (c) {
      setRejectFormModal(true);
    }
  };
  const cancelFormHandler = () => {
    setRejectFormModal(false);
  };
  const generate_quoting_price_request = async () => {
    setIsLoader(true);
    try {
      const res = await generateQuotingPriceRequest(formik?.values?._id);
      if (res.status === 200 || res.status === 201) {
        formik?.setValues({
          ...formik?.values,
          quoting_request_status: res.data?.quoting_request_status,
        });
        toast.success(res.data.message || "Quoting request sent successfully");
      } else {
        toast.error(res.data.message || "Quoting request couldn't be send");
      }
    } catch (err) {
      toast.error(
        err?.response.data.message || "Quoting request couldn't be send"
      );
      console.log("👊 ~ constgenerate_quoting_price_request=async ~ err:", err);
    }
    setIsLoader(false);
  };
  useEffect(() => {
    const getLeadDetail = async () => {
      try {
        const res = await getLeadsById(id);
        if (res?.status === 200) {
          formik.setValues({
            ...res.data,
            id: id,
          });
        }
      } catch (error) {
        console.error(
          "Error ~ file: AddUpdateForm.jsx:157 ~ getJob ~ error",
          error
        );
        errorMessage({
          payload: error?.response.message,
          action: "Lead",
          msg: "loaded",
        });
      }
    };
    const intervalId = setInterval(() => {
      if (formik.values.quoting_request_status === "Pending") {
        getLeadDetail();
      } else {
        clearInterval(intervalId);
      }
    }, 10000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.quoting_request_status]);

  const renderItem = (label, value) => (
    <div className="flex gap-x-1">
      <label className="text-client-50">{label}</label>{" "}
      <span>{typeof value === "boolean" ? (value ? "Yes" : "No") : value}</span>
    </div>
  );
  const renderItemHeader = (label) => (
    <div className="col-span-1 text-client-100 pb-1.25 pl-0 border-b border-client-50 ml-2.5 mb-1.25 font-bold">
      {label}
    </div>
  );
  const labels = {
    car_year: "Year:",
    car_make: "Make:",
    car_model: "Modal:",
    is_neutral: "Is neutral?",
    car_color: "Color:",
    drivetrain: "Drivetrain:",
    duty_type: "Type Of Duty:",
    is_tires_ok: "Are the tires okay?",
    car_type: "Vehicle type?",
    is_running: "Is the vehicle still operable/running?",
    tow_type: "Type of tow:",
    has_spare_tire: "Does the client has a good spare tire?",
    which_tire_to_be_change: "Which Tire needs to be change?",
    tire_size: "Tire size:",
    used_or_new_tire: "Does the client used or new tire?",
    needs_to_be_jump: "How many better needs to be jump?",
    tire_count_needs_to_inflate: "How many tires need to inflate?",
    which_tire_needs_to_inflate: "Which tire needs to be inflate?",
    days_to_stored: "How many days needs to be stored?",
    fuel_type: "What type of fuel do they need?",
    gallons: "How many gallons?",
    is_payout: "Is there a payout?",
    payout_amount: "Amount of Payout:",
    is_paperwork_needed: "Are there paperwork needed?",
    is_trailer: "Does it have a trailer attached?",
    weight: "Weight (lb):",
    height: "Height (ft):",
    length: "Length (ft):",
    width: "Width (ft):",
    axles_count: "How many axles does it have?",
    is_dually: "Is it Dually?",
    loaded_with: "What is it loaded with?",

    lockout_type: "Is it a door lockout or trunk lockout?:",
    locksmith_type: "What type of Locksmith Service?:",
    locksmith_specify: "Specify:",
    to_be_towed: "What is the equipment or item that needs to be towed?:",
    lugnuts_type: "Does the client has regular lugnuts or locking lugnuts?:",
    lugnuts_keys_to_remove:
      "If its locking lugnuts, do they the keys to remove it?:",
    is_replacement_vehicle_same: "Same replacement vehicle?:",
    replacement_car_year: "Replacement vehicle year:",
    replacement_car_make: "Replacement vehicle Make:",
    replacement_car_model: "Replacement vehicle Modal:",
    replacement_car_color: "Replacement vehicle Color:",
    replacement_license_number: "License Plate Number:",
  };
  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      {isLoader ? <DotsLoader /> : null}
      {isRejectFormModal && (
        <LeadRejectForm
          modalTitle="Decline Lead"
          onCancelForm={cancelFormHandler}
          leadId={formik?.values?._id}
        />
      )}
      <div className="relative max-w-[80%]">
        <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
          <button
            onClick={onCancelModal}
            className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div
          className={`relat z-10 w-auto w-full md:max-w md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white ${modalClass}`}
          //   ref={modalRef}
        >
          {jobDetail ? (
            <div className="container-fluid mt-2 text-center">
              <div className="inline-block bg-white my-0 mx-auto p-4">
                <div className="w-[100px] h-[100px] bg-[#3dcb31] rounded-full m-auto mt-[-70px] flex justify-center items-center">
                  <i className="text-white font-extrabold text-5xl text-center">
                    ✓
                  </i>
                </div>
                <label className="text-black font-pop text-3xl mb-2.5 font-medium">
                  Thank You!
                </label>
                <p className="text-[#7b828a] font-pop text-base m-0">
                  Your Service request has been recived. Your job number is{" "}
                  <span
                    className="text-[#027bff]"
                    onClick={() => {
                      onCancelModal();
                      navigate(`/jobs/update/${formik.values.id}`);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {jobDetail?.job_number}
                  </span>
                  . Call or text us on this{" "}
                  <span className="text-[#027bff]">
                    {" "}
                    {jobDetail?.call_text_on}
                  </span>{" "}
                  for any query.
                </p>
              </div>
            </div>
          ) : (
            <div className="pt-0 w-auto px-3.5 m-auto ">
              <div className={`md:p-4 ${childrenClass}`}>
                <div className="relative flex gap-x-2 ml-3 w-auto">
                  <div className="min-w-[200px]">
                    <FormSelectInput
                      name="payment_type"
                      label="Payment Type"
                      formik={formik}
                      options={[
                        { label: "C.O.D", value: "COD" },
                        { label: "Insurance", value: "Insurance" },
                      ]}
                      labelProp="label"
                      valueProp="value"
                      nullOption="No"
                      className="w-[200px]"
                    />
                  </div>
                  <FormInput
                    name="distance"
                    label="Distance"
                    formik={formik}
                    type="text"
                    disabled
                  />
                </div>
                <div
                  className={["flex md:flex-row flex-col gap-x-2"].join(" ")}
                >
                  <div className="sm:col-span-12 md:col-span-6 mb-4 md:!mb-0  bg-white rounded-2xl shadow-client">
                    <div className="flex justify-center items-center flex-col p-4">
                      <h5 className="text-client-100 pb-2.5 w-full border-b border-client-100">
                        Here is the estimated cost of your service.
                      </h5>
                      <img
                        src={tow_truck_no_bg}
                        alt=""
                        className="w-full max-w-[600px]"
                      />
                      <h2 className="mt-5">
                        Best price - {quote?.currency}
                        {formik?.values?.quoting_request_price ||
                          quote?.service_cost}
                      </h2>
                      {formik?.values?.quoting_request_status === "Pending" ? (
                        <div>
                          Your request has been sent, please wait for approval.
                        </div>
                      ) : formik?.values?.quoting_request_status ===
                        "Quoted" ? (
                        <div className="text-green-600 !mt-4">
                          Your request has been approved.
                        </div>
                      ) : formik?.values?.quoting_request_status ===
                        "Rejected" ? (
                        <div className="text-red-600 !mt-4">
                          Your request has been rejected.
                        </div>
                      ) : (
                        <div>
                          Do you want to send this price to Quoting Team?{" "}
                          <button
                            className="text-blue-500 cursor-pointer hover:underline"
                            onClick={generate_quoting_price_request}
                          >
                            Click here
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="sm:col-span-12 md:col-span-6 p-4 bg-white rounded-2xl shadow-client">
                    <h5 className="text-client-100 pb-2.5 w-full border-b border-client-100">
                      Review your information
                    </h5>

                    {/* Basic  Information */}
                    <div>
                      {renderItemHeader("Basic Information")}
                      <div className="ml-3 grid grid-cols-1">
                        {renderItem("Name:", formik?.values.name)}
                        {renderItem("Phone:", formik?.values.phone)}
                        {renderItem(
                          "Alternate No:",
                          formik?.values.alternate_phone
                        )}
                        {renderItem("Email:", formik?.values.email)}
                      </div>
                    </div>
                    {/* Pickup & Drop Location */}
                    <div>
                      {renderItemHeader("Pickup & Drop Location")}
                      <div className="ml-3 grid grid-cols-1">
                        {renderItem(
                          "Pickup Address:",
                          formik?.values.complete_pickup_address
                        )}

                        {formik?.values.complete_drop_address
                          ? renderItem(
                              "Drop Address:",
                              formik?.values.complete_drop_address
                            )
                          : null}
                        {formik?.values.complete_drop_2_address
                          ? renderItem(
                              "2nd Drop off Address:",
                              formik?.values.complete_drop_2_address
                            )
                          : null}
                        {formik?.values.distance
                          ? renderItem("Distance:", formik?.values.distance)
                          : null}
                      </div>
                    </div>
                    {/* Service & Car Info */}
                    {/* <div>
                      {renderItemHeader("Service & Car Info")}
                      <div className="ml-3 grid grid-cols-1 gap-4">
                        {Object.keys(formik?.values?.extra_fields).map(
                          (key) => {
                            // Find the label from the `labels` object using the key
                            const label = labels[key] || key; // If no label is found, fall back to the key itself
                            return renderItem(
                              label,
                              formik?.values?.extra_fields[key]
                            );
                          }
                        )}
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="p-4 bg-white rounded-2xl shadow-client mt-2">
                  <div>
                    {renderItemHeader("Service & Car Info")}
                    {formik?.values?.extra_fields ? (
                      <div className="ml-3 grid grid-cols-3 gap-x-4 gap-y-1">
                        {Object.keys(formik?.values?.extra_fields).map(
                          (key) => {
                            // Find the label from the `labels` object using the key
                            const label = labels[key] || key; // If no label is found, fall back to the key itself
                            return renderItem(
                              label,
                              formik?.values?.extra_fields[key]
                            );
                          }
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div>
                <FormRadioGroup
                  name="schedule_on"
                  labelProp="label"
                  valueProp="value"
                  options={[
                    { label: "I want driver to come now.", value: "false" },
                    {
                      label: "I want to schedule a driver for later.",
                      value: "true",
                    },
                  ]}
                  isCheck={isSchedule}
                  isOnChange={(ele) => setIsSchedule(ele)}
                  type="text"
                  isRadio={true}
                  convertor={(value) => value === "true"}
                  isFloat={false}
                />
                {isSchedule === "true" && (
                  <div className="mb-2 flex justify-start items-center col-md-6">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={formik?.values?.scheduled_on}
                        onChange={(val) => {
                          formik?.setFieldValue("scheduled_on", val.toString());
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
              <div className="flex flex-row gap-x-2 justify-end">
                {formik.values?.quoting_request_status &&
                (formik.values?.quoting_request_status === "Quoted" ||
                  formik.values?.quoting_request_status === "Rejected") ? (
                  <button
                    type="button"
                    className="py-1.5 px-3 align-middle next rounded-0 text-white bg-client-100 border-client-100 float-right hover:bg-[#007bff]"
                    onClick={generate_quoting_price_request}
                  >
                    Re-Submit Quote
                  </button>
                ) : null}
                <button
                  type="button"
                  className="py-1.5 px-3 align-middle next rounded-0 text-white bg-red-400 float-right"
                  onClick={openRejectFormHandler}
                >
                  Decline Job
                </button>
                <button
                  type="submit"
                  className="py-1.5 px-3 align-middle next rounded-0 text-white bg-client-100 border-client-100 float-right hover:bg-[#007bff]"
                  onClick={formik.handleSubmit}
                >
                  Accept Payment, Pay later
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuoteModal;
